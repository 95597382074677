import React from "react";
import {connect} from 'react-redux';

const CustomDivider = (props) => {
  const divStyles = {
    marginBottom: `${props.marginBottom}px`,
    height: "1px",
    backgroundColor:
      props.color === "bright" ? props.colors.bright : props.colors.accent,
  };
  return (
    <div style={divStyles} />
  );
};

const mapStateToProps = (state) => ({
  colors: state.coms.colors
})

export default connect(mapStateToProps,{})(CustomDivider);