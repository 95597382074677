import React from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";

const SplashScreen = () => {
  return(
    <div className='splash-screen'>
      <CircularProgress color="secondary" />
    </div>
  )
}

export default SplashScreen