import React, { useState, useEffect } from "react";
import CustomButton from "./customButton";
import { connect } from "react-redux";
import { updateUser } from "../../store/actions/authActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const UserInfoForm = (props) => {
  let target, value;
  const [gridWidth, setGridWidth] = useState(6);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    courtesy: props.user.courtesyTitle,
    fname: props.user.fname,
    lname: props.user.lname,
    email: props.user.email,
    mobile: props.user.mobile,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    target = event.target.name;
    value = event.target.value;
    setCredentials((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await props.updateUser({
      ...credentials,
      token: props.user.token,
      userId: props.user.userId,
      username: props.user.username,
    });
    setLoading(false);
    if (res) setErrors(res);
    else props.handleClose();
  };

  useEffect(()=> {
    if (props.dimensions.width < 450) setGridWidth(12);
    else setGridWidth(6);
  },[props.dimensions])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            label="Courtesy"
            name="courtesy"
            value={credentials.courtesy}
            onChange={handleChange}
            variant="outlined"
          >
            <MenuItem value="Mr">Mr</MenuItem>
            <MenuItem value="Mrs">Mrs</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={gridWidth}>
          <TextField
            label="First Name"
            placeholder="Enter your first name"
            name="fname"
            type="text"
            value={credentials.fname}
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            error={!!errors.fname}
            helperText={errors.fname}
          />
        </Grid>
        <Grid item xs={gridWidth}>
          <TextField
            label="Last Name"
            placeholder="Enter your last name"
            name="lname"
            type="text"
            value={credentials.lname}
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            error={!!errors.lname}
            helperText={errors.lname}
          />
        </Grid>
        <Grid item xs={gridWidth}>
          <TextField
            label="Contact"
            placeholder="1234567890"
            name="mobile"
            type="text"
            value={credentials.mobile}
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            error={!!errors.mobile}
            helperText={errors.mobile}
          />
        </Grid>
        <Grid item xs={gridWidth}>
          <TextField
            label="Email"
            placeholder="example@somewhere.com"
            name="email"
            type="email"
            value={credentials.email}
            onChange={handleChange}
            fullWidth={true}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="form-button-container">
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <CustomButton label={props.buttonName} type="submit" />
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  dimensions: state.coms.dimensions,
});

export default connect(mapStateToProps, { updateUser })(UserInfoForm);
