import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Cookies from "js-cookie";
import "./styles/styles.scss";

import { autologin } from "./store/actions/authActions";
import PrivateRoute from "./utils/privateRoute";
import PublicRoute from "./utils/publicRoute";
import { mainLoad, setDimensions } from "./store/actions/comActions";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";

import Login from "./components/login";
import Landing from "./components";
import Register from "./components/register";
import Profile from "./components/profile";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "rgb(237, 106, 0)",
      main: "rgb(212, 96, 0)",
      dark: "rgb(191, 86, 0)",
    },
    secondary: {
      main: orange[500],
    },
  },
});

const App = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  useEffect(() => {
    console.log("Searching for cookies");
    let cookieData = Cookies.get("ds_creds");
    if (cookieData) {
      console.log("Found cookies!");
      cookieData = cookieData.split(" ");
      store.dispatch(autologin(cookieData[0], cookieData[1], cookieData[2]));
    } else {
      store.dispatch(mainLoad(false));
    }
    const handleResize = () => {
      store.dispatch(setDimensions(getWindowDimensions()));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/register" component={Register} />
            <PrivateRoute exact path="/" component={Landing} />
            <PrivateRoute path="/profile" component={Profile} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
