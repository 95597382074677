import React, { useState } from "react";
import { connect } from "react-redux";
import { changePassword } from "../../store/actions/authActions";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomButton from "./customButton";
import { CustomAlert } from "./customAlert";

const UserPasswordForm = (props) => {
  let target, value;
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    cpassword: "",
    password: "",
    password2: "",
  });
  const [messages, setMessages] = useState({});

  const handleChange = (event) => {
    target = event.target.name;
    value = event.target.value;
    setCredentials((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await props.changePassword({
      ...credentials,
      token: props.user.token,
      userId: props.user.userId,
      username: props.user.username,
    });
    setLoading(false);
    console.log(res);
    setMessages(res);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <div className="pass-form-element">
            <div className="pass-form-title">Password</div>
            <TextField
              label="Current Password"
              placeholder="**********"
              name="cpassword"
              type="password"
              value={credentials.cpassword}
              onChange={handleChange}
              variant="outlined"
              error={!!messages.cpassword}
              helperText={messages.cpassword}
            />
          </div>
        </Grid>
        <Grid item>
          <div className="pass-form-element">
            <div className="pass-form-title">New Password</div>
            <TextField
              label="New Password"
              placeholder="**********"
              name="password"
              type="password"
              value={credentials.password}
              onChange={handleChange}
              variant="outlined"
              error={!!messages.password}
              helperText={messages.password}
            />
          </div>
        </Grid>
        <Grid item>
          <div className="pass-form-element">
            <div className="pass-form-title">Confirm Password</div>
            <TextField
              label="Confirm Password"
              placeholder="**********"
              name="password2"
              type="password"
              value={credentials.password2}
              onChange={handleChange}
              variant="outlined"
              error={!!messages.password2}
              helperText={messages.password2}
            />
          </div>
        </Grid>
        <Grid item>
          <div className="form-button-container-side">
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <CustomButton label="Update Password" type="submit" />
            )}
          </div>
        </Grid>
        <Grid item>
          {messages.error ? (
            <CustomAlert severity="error" content={messages.error} />
          ) : messages.success ? (
            <CustomAlert severity="success" content={messages.success} />
          ) : (
            <div />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { changePassword })(UserPasswordForm);
