import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./shared/header";
import { ParamTable, ParamRow } from "./shared/paramsView";
import CustomDivider from "./shared/customDivider";
import UserInfoForm from "./shared/userInfoForm";
import UserPasswordForm from "./shared/userPasswordForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InfoCard from "./shared/infoCard";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";

const Profile = (props) => {
  const [gridWidth, setGridWidth] = useState(6);
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const subbed = false;

  useEffect(() => {
    if (props.dimensions.width < 1000) setGridWidth(12);
    else setGridWidth(6);
  }, [props.dimensions]);

  const handleTab = (_, newValue) => {
    setTab(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const general = (
    <Grid container>
      <Grid item xs={gridWidth}>
        <InfoCard
          title="User Info"
          action={
            <IconButton color="primary" onClick={handleOpen}>
              <EditIcon />
            </IconButton>
          }
        >
          <ParamTable>
            <ParamRow title="Phone" value={props.user.mobile} />
            <ParamRow title="Email" value={props.user.email} />
          </ParamTable>
        </InfoCard>
      </Grid>
      <Grid item xs={gridWidth}>
        <InfoCard title="Connected Apps">
          <ParamTable>
            <ParamRow title="Thaenkood" value="Some_user_id" />
          </ParamTable>
        </InfoCard>
      </Grid>
    </Grid>
  );

  const settings = (
    <div>
      <div className="custom-titles">Update Password</div>
      <UserPasswordForm />
    </div>
  );

  return (
    <React.Fragment>
      <Header />
      <div className="profile-page">
        <section className="profile-header">
          <div className="profile-picture" />
          <div className="profile-header-info">
            <div className="profile-name">
              {`${props.user.fname} ${props.user.lname}`}
            </div>
            {subbed ? (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<CheckIcon />}
              >
                Subscribed
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
              >
                Subscribe
              </Button>
            )}
          </div>
        </section>
        <Tabs value={tab} onChange={handleTab}>
          <Tab label="General" />
          <Tab label="Settings" />
        </Tabs>
        <CustomDivider marginBottom="10" color="bright" />
        {tab === 0 ? general : tab === 1 ? settings : <div />}
      </div>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Edit Profile</DialogTitle>
        <CustomDivider height="10" />
        <div className="profile-dialog-body">
          <UserInfoForm
            handleClose={handleClose}
            user={props.user}
            buttonName="Update"
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  dimensions: state.coms.dimensions,
});

export default connect(mapStateToProps, {})(Profile);
