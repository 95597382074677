import React, { useState } from "react";
import Header from "./shared/header";
import Button from "@material-ui/core/Button";

const Landing = () => {
  const [loading, setLoading] = useState(false);
  const handlePay = () => {
    console.log("Paying now");
    alert("Need to add server API for this");
  };
  return (
    <div>
      <Header />
      <div className="main-info">
        <h2>Welcome To Digital Sheets</h2>
        <p>
          I'll be putting some content here. Clicking the button below will
          initiate the payment
        </p>
        <Button color="primary" variant="contained" onClick={handlePay}>
          Pay
        </Button>
      </div>
    </div>
  );
};

export default Landing;
