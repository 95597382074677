import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from '@material-ui/core/CardContent';

const InfoCard = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      margin: 10,
    },
    header: {
      color: props.colors.accent,
      padding: 10,
      fontSize: props.sizes.miniTitleSize
    },
    content: {
      padding:10,
      paddingTop:0,
    }
  }));
  const classes = useStyles();
  return (
    <Card className={classes.root} variant='outlined'>
      <CardHeader
        className={classes.header}
        disableTypography={true}
        title={props.title}
        action={props.action}
      />
      <CardContent className={classes.content}>
        {props.children}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  colors: state.coms.colors,
  sizes: state.coms.sizes,
});

export default connect(mapStateToProps, {})(InfoCard);
