export const loginExtractor = (data) => {
  return {
    orgId: data.details.org_id,
    school: data.details.school,
    userId: data.details.user_id,
    username: data.details.user_name,
    userType: data.details.user_type,
    courtesyTitle: data.details.courtesy_title,
    fname: data.details.first_name,
    lname: data.details.last_name,
    email: data.details.email,
    mobile: data.details.mobile,
    token: data.token,
  };
};

export const autologinExtractor = (data) => {
  return {
    orgId: data.org_id,
    school: data.school,
    userId: data.user_id,
    username: data.user_name,
    userType: data.user_type,
    courtesyTitle: data.courtesy_title,
    fname: data.first_name,
    lname: data.last_name,
    email: data.email,
    mobile: data.mobile,
    token: data.token,
  };
};
