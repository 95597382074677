import Axios from "axios";

let urls = {
  test: `https://www.tossz.in/dws_app/public`,
  development: `https://www.tossz.in/dws_app/public`,
  production: `https://www.tossz.in/dws_app/public`,
};
const api = Axios.create({
  baseURL: urls[process.env.NODE_ENV],
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export default api;
