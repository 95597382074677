import React from 'react'

const Logo = () => {
  return (
    <div className='main-logo'>
      DigitalSheets
    </div>
  )
}

export default Logo