import api from "../../utils/api";
import {
  loginValidation,
  registerValidation,
  updateValidation,
  passwordValidation,
} from "../../utils/validation";
import Cookies from "js-cookie";
import { SET_USER, CLEAR_USER, SET_MAIN_LOAD } from "./types";
import { loginExtractor, autologinExtractor } from "../../utils/extractor";

export const register = (credentials) => async (dispatch) => {
  const errors = registerValidation(credentials);
  if (errors) {
    return errors;
  } else {
    try {
      const params = {
        first_name: credentials.fname,
        last_name: credentials.lname,
        courtesy_title: credentials.courtesy,
        email: credentials.email,
        user_name: credentials.username,
        mobile: credentials.mobile,
        password: credentials.password,
      };
      await api.post("/dws_insertUser", params);
      dispatch(login(credentials.username, credentials.password));
    } catch (e) {
      console.log(e);
      return {
        register: "Failed to register",
      };
    }
  }
};

export const login = (credentials) => async (dispatch) => {
  const errors = loginValidation(credentials);
  if (errors) {
    return errors;
  } else {
    try {
      const res = await api.get("/dws_userInfo", {
        params: {
          user_name: `${credentials.username}`,
          password: `${credentials.password}`,
        },
      });
      if (res.status === 200) {
        const params = loginExtractor(res.data);
        Cookies.set(
          "ds_creds",
          `${params.username} ${params.token} ${params.userId}`
        );
        dispatch({
          type: SET_USER,
          payload: params,
        });
      } else {
        return {
          login: res.data.message,
        };
      }
    } catch (e) {
      console.log(e);
      return {
        login: "Failed to login",
      };
    }
  }
};

export const autologin = (username, token, userId) => async (dispatch) => {
  try {
    const res = await api.post("/dws_userDetailsByUserId", {
      user_name: username,
      token: token,
      user_id: userId,
    });
    if (res.status === 200) {
      const params = autologinExtractor({ ...res.data[0], token });
      dispatch({
        type: SET_USER,
        payload: params,
      });
    } else {
      dispatch(logout());
    }
    dispatch({
      type: SET_MAIN_LOAD,
      payload: false,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateUser = (credentials) => async (dispatch) => {
  console.log("updating user");
  const errors = updateValidation(credentials);
  if (errors) {
    return errors;
  } else {
    try {
      const params = {
        token: credentials.token,
        user_id: credentials.userId,
        first_name: credentials.fname,
        last_name: credentials.lname,
        courtesy_title: credentials.courtesy,
        email: credentials.email,
        user_name: credentials.username,
        mobile: credentials.mobile,
        status: true,
      };
      console.log(params);
      await api.post("/dws_updateUser", params);
      await dispatch(
        autologin(credentials.username, credentials.token, credentials.userId)
      );
    } catch (e) {
      console.log(e);
      return {
        update: "Failed to Update",
      };
    }
  }
};

export const changePassword = (credentials) => async (dispatch) => {
  const errors = passwordValidation(credentials);
  if (errors) {
    return errors;
  } else {
    try {
      const params = {
        token: credentials.token,
        user_name: credentials.username,
        user_id: credentials.userId,
        password: credentials.cpassword,
        new_password: credentials.password,
      };
      const res = await api.post("/dws_updateUserPassword", params);
      if (res.status !== 200) {
        return {
          error: res.data.message,
        };
      } else {
        return {
          success: res.data.message,
        };
      }
    } catch (e) {
      console.log(e);
      return {
        error: "Failed to Update Password",
      };
    }
  }
};

export const logout = (username, token) => (dispatch) => {
  console.log("initiating logout");
  Cookies.remove("ds_creds");
  api.post("/dws_logout", {
    user_name: username,
    token: token,
  });
  dispatch({
    type: CLEAR_USER,
  });
};
