import React from 'react'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export const ParamTable = (props) => (
  <Table size='small'>
    <TableBody>
      {props.children}
    </TableBody>
  </Table>
)

export const ParamRow = (props) => {
  return (
    <TableRow>
    <TableCell>{props.title}</TableCell>
      <TableCell>{props.value}</TableCell>
    </TableRow>
  )
}

// export const ParamRow = (props) => {
//   return (
//     <div className='param-set'>
//       <div className='param-set-title'>
//         {props.title}
//       </div>
//       <div>
//         {props.value}
//       </div>
//     </div>
//   )
// }